<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nova Concessionaria" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectConcessionarias || !selectConcessionarias.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de concessionarias -->
        <DataTable ref="dt" :value="concessionarias" v-model:selection="selectConcessionarias" dataKey="id" :paginator="true" :rows="50" :filters="filters" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]"
         currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gereneciar Concessionarias</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="id_tipo" header="Tipo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Tipo</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column field="razao_social" header="Razão Social" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Razão Social</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>
          <Column field="sigla" header="Sigla" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Sigla</span>
              {{ slotProps.data.sigla }}
            </template>
          </Column>
          <Column field="email" header="E-mail" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">E-mail</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="cnpj" header="CNPJ" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">CNPJ</span>
              <span>{{ slotProps.data.cnpj }}</span>
            </template>
          </Column>
          <Column field="site" header="Site" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Site</span>
              <span>{{ slotProps.data.site }}</span>
            </template>
          </Column>
          <Column field="estado" header="Estado" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Site</span>
              {{ this.desc_estados[slotProps.data.id_estado] }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarConcessionariaModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        
        <!-- Modal de edição de concessionaria -->
        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Editar Concessionaria" :modal="true" class="p-fluid">
          <div class="field">
            <label for="Tipo" class="mb-3">Tipo</label>
            <Dropdown id="id_tipo" required="true" v-model="concessionaria.id_tipo" :options="tipos_concess" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !concessionaria.id_tipo }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !concessionaria.id_tipo">Tipo é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">Razão Social</label>
            <InputText id="bome" v-model.trim="concessionaria.razao_social" required="true" autofocus :class="{ 'p-invalid': submitted && !concessionaria.razao_social }" />
            <small class="p-invalid" v-if="submitted && !concessionaria.razao_social">Razão Social é obrigatória.</small>
          </div>
          <div class="field">
            <label for="name">Sigla</label>
            <InputText id="name" v-model.trim="concessionaria.sigla" required="true" autofocus :class="{ 'p-invalid': submitted && !concessionaria.sigla }" />
            <small class="p-invalid" v-if="submitted && !concessionaria.sigla">Sigla é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">E-mail</label>
            <InputText  id="name" v-model="concessionaria.email" placeholder="E-mail" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" />
          </div>
          <div class="field">
            <label for="name">CNPJ</label>
            <InputText id="name" v-model.trim="concessionaria.cnpj" required="true" autofocus :class="{ 'p-invalid': submitted && !concessionaria.cnpj }" />
            <small class="p-invalid" v-if="submitted && !concessionaria.cnpj">CNPJ é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">Site</label>
            <InputText id="name" v-model.trim="concessionaria.site" required="true" autofocus :class="{ 'p-invalid': submitted && !concessionaria.site }" />
            <small class="p-invalid" v-if="submitted && !concessionaria.site">Site é obrigatório.</small>
          </div>
          <div class="field">
            <label for="Status" class="mb-3">Estado</label>
            <Dropdown id="empresa" required="true" v-model="concessionaria.id_estado" :options="estados" optionLabel="label" optionValue="value" placeholder="Selecione um estado" :class="{ 'p-invalid': submitted && !concessionaria.id_estado }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !concessionaria.id_estado">Estado é obrigatório.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarConcessionaria" />
          </template>
        </Dialog>

        <!-- Modal deletar usuario -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="concessionaria">Certeza que vai apagar: <b>{{ concessionaria.razao_social }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarConcessionaria" />
          </template>
        </Dialog>

        <!-- Modal deletar varios usuarios -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="concessionaria">Apagar Concessionarias selecionados?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>

  import { FilterMatchMode } from "primevue/api";

  export default {
    data() {
      return {
        concessionarias: [],
        concessionaria: {},
        selectConcessionarias: null,
        limite_concessionaria: false,
        selectedProducts: null,
        productDialog: false,
        estados: [],
        desc_estados: [],
        id_estados: "",
        tipos_concess: [],
        desc_tipos_concess: [],
        tipo_descricao: "",
        deleteProductDialog: false,
        filters: {},
        submitted: false,
        deleteProductsDialog: false,
        
        /*
        usuarios: [],
        id_empresa: "",
        empresas: [],
        
        usuario: {},
        mostrar_usina: false,
        status: "",
        nivel_acesso: "",
        usinas: [],
        id_usina: "",
        lista_acesso: [
          { label: "Administrador", value: 1 },
        ],
        statuses: [
          { label: "Inativo", value: 0 },
          { label: "Ativo", value: 1 },
        ],
        limite_usuario: false
        */
      };
    },
    // productService: null,
    created() {
      this.initFilters();
    },
    mounted() {
      this.listarConcessionarias();
      this.listarEstados();
      this.listarTiposConcessionarias();
      // this.listarUsuario();
      // 
    },


    methods: {
      // listar concessionarias no 
      listarConcessionarias(){
        this.axios
          .get("/concessionarias/listar")
          .then((response) => {
            this.concessionarias = response.data.dados;
            // this.limite_concessionaria = false
            // let user = JSON.parse(localStorage.getItem('user'))
            // console.log(this.concessionarias)
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      openNew() {
        this.concessionaria = {};
        this.submitted = false;
        this.productDialog = true;
      },

      editProduct(concessionaria) {
        this.productDialog = true;
        this.concessionaria = { ...concessionaria };
        // console.log(this.usuario)
      },

      listarEstados() {
        this.axios
          .post("/auxiliar/estados")
          .then((response) => {

            let options = [];
            let desc = {};
            response.data.dados.map((el) => {

              let option = { value: el["id"], label: el["nome"] };
              desc[el["id"]] = el["nome"];
              options.push(option);

            });
            this.estados = options;
            this.desc_estados = desc;

            console.log(this.estados)
          })
          .catch((error) => {
            console.log(error);
          });
      },
      listarTiposConcessionarias() {
        this.axios
          .post("/concessionarias/tipos/listar")
          .then((response) => {

            let options_concess = [];
            let desc_concess = {};
            response.data.dados.map((el_concess) => {

              let option_concess = { value: el_concess["id"], label: el_concess["descricao"] };
              desc_concess[el_concess["id"]] = el_concess["descricao"];
              options_concess.push(option_concess);

            });
            this.tipos_concess = options_concess;
            this.desc_tipos_concess = desc_concess;

            console.log(this.desc_tipos_concess)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },




      // criar/editar usuario
      salvarConcessionaria() {
        this.submitted = true;

        if (this.concessionaria.id) {
          // Ediçao de concessionaria
          this.axios
            .post("concessionarias/editar", this.concessionaria)
            .then(() => {

              // console.log(this.concessionaria)

              this.productDialog = false;
              this.concessionaria = {};
              this.listarconcessionaria();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar novo concessionaria
          this.axios
            .post("/concessionarias/adicionar", this.concessionaria)
            .then(() => {

              // alert(response.data.data)

              this.productDialog = false;
              this.concessionaria = {};
              this.listarconcessionaria();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      apagarConcessionaria() {
        this.axios
        // console.log(this.concessionaria)
          .post("/concessionarias/apagar", this.concessionaria)
          .then(() => {
            
            this.listarconcessionaria();
            this.deleteProductDialog = false;
            this.concessionaria = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usuário apagado com sucesso!",
              life: 3000,
            });
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      apagarConcessionariaModal(concessionaria) {
        this.concessionaria = concessionaria;
        this.deleteProductDialog = true;
      },

      // botao de excluir selecionados
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },

      

      
      

      
      deleteSelectedProducts() {
        this.concessionarias = this.concessionarias.filter(
          (val) => !this.selectConcessionarias.includes(val)
        );
 
        let obj = this.selectConcessionarias;
        Object.keys(obj).forEach((key) => {
          this.axios
            .post("/concessionarias/apagar", obj[key])
            .then(() => {
              this.listarUsuario();
              this.deleteProductDialog = false;
              this.usuario = {};
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Usário apagado com sucesso!s",
                life: 3000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        });
 
        this.deleteProductsDialog = false;
        this.selectConcessionarias = null;
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Products Deleted",
          life: 3000,
        });
      },
      /*

      */
     
     exportCSV() {
       this.$refs.dt.exportCSV();
      },
      
      
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
      
    },
  };


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
